import { Header, Heading, LoadingPlaceholder } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { Footer } from 'components/Footer';
import { ErrorBlock, SuccessBlock } from 'components/InfoBlock';
import { useTranslations } from 'components/Intl/useTranslations';
import { StyledTileContent } from 'components/MaintenanceMessages/maintenance-messages.styles';
import Page from 'components/Page';
import { Page as StyledPage } from 'components/Page/page.styles';
import { Tile } from 'components/Tiles';
import { useAuthSubscription } from 'containers/auth';
import React, { useEffect, useState } from 'react';
import { Container } from 'styled-bootstrap-grid';
import api from 'utils/api';
import { useSearchParams } from 'utils/hooks/useSearchParams';
import * as S from './manage-privacy-settings.styles';

const intlPrefix = 'pages.manage-privacy-settings';

interface IManagePrivacySettingsProps {
  isUnauthenticated?: boolean;
  subscriptionAction: SubscriptionAction;
}

enum SettingsType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  TELEFOON = 'TELEFOON',
  POST = 'POST',
  ONLINE = 'ONLINE',
  USAGE_DATA = 'GEBRUIKSGEGEVENS',
  CLIENT_PROFILE = 'KLANTPROFIELEN',
  LOCATION_DATA = 'LOCATIEGEGEVENS',
  CUSTOMER_RESEARCH = 'KLANTONDERZOEK',
  NONE = 'NONE',
}

type QueryStringParams = {
  type: SettingsType;
  token: string;
};

export enum SubscriptionAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
}

const getSuccessMessage = (settingsType: SettingsType, subscriptionAction: SubscriptionAction, translate: any) => {
  const messages = {
    [SubscriptionAction.SUBSCRIBE]: translate(`${intlPrefix}.subscribe.success-message.${settingsType}`),
    [SubscriptionAction.UNSUBSCRIBE]: translate(`${intlPrefix}.unsubscribe.success-message.${settingsType}`),
  };

  return messages[subscriptionAction];
};

const ManagePrivacySettings = ({ isUnauthenticated, subscriptionAction }: IManagePrivacySettingsProps) => {
  const { translate, translateHtml, intl } = useTranslations();
  const { contactId, productMsisdn } = useAuthSubscription();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getCurrentSearchParams } = useSearchParams();
  const searchParams = getCurrentSearchParams();

  const settingsType = searchParams.get('type') as SettingsType;

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (settingsType) {
        try {
          if (isUnauthenticated) {
            const token = searchParams.get('token')?.replace(/ /g, '+');
            const payloadWithToken: QueryStringParams = {
              type: settingsType ?? SettingsType.NONE,
              token: token ?? '',
            };

            await api.post(`/promos/${subscriptionAction}`, payloadWithToken, { omitAuthHeader: true });
            setIsSuccess(true);
            setIsLoading(false);
          } else if (productMsisdn && contactId) {
            await api.post(`/my/promos/${subscriptionAction}`, {
              type: settingsType ?? SettingsType.NONE,
              msisdn: productMsisdn,
              contact_id: contactId,
            });
            setIsSuccess(true);
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
          setIsFailed(true);
          setIsLoading(false);
        }
      } else {
        setIsFailed(true);
        setIsLoading(false);
      }
    };

    setIsLoading(true);
    fetchData();
  }, [productMsisdn, contactId]);

  const successMessage = getSuccessMessage(settingsType, subscriptionAction as SubscriptionAction, translate);

  const title = intl.formatMessage({ id: `${intlPrefix}.title.${subscriptionAction}` });

  return (
    <StyledPage title={title} meta-title={title} data-testid="manage-privacy-settings">
      <Header
        changeBillingCustomer={() => null}
        billingCustomers={[]}
        activeBillingCustomer={null}
        shouldShowMenuItem={() => Promise.resolve(false)}
        shouldRenderSubmenu={false}
      />
      <S.ManageSubscriptionContainer>
        <Container className="container">
          <Page.Wrapper>
            <Page.FullWidthContent>
              <Tile>
                <StyledTileContent>
                  <Heading.H1 as={HeadingTags.H2} data-testid="manage-privacy-settings-title">
                    {translate(`${intlPrefix}.title.${subscriptionAction}`)}
                  </Heading.H1>
                  {isLoading && <LoadingPlaceholder size="large" width={100} />}
                  {!isLoading && isSuccess && <SuccessBlock>{successMessage}</SuccessBlock>}
                  {!isLoading && isFailed && (
                    <ErrorBlock testId="manage-privacy-settings-error">
                      <span>{translateHtml(intlPrefix, 'failure-message')}</span>
                    </ErrorBlock>
                  )}
                </StyledTileContent>
              </Tile>
            </Page.FullWidthContent>
          </Page.Wrapper>
        </Container>
      </S.ManageSubscriptionContainer>
      <Footer />
    </StyledPage>
  );
};

export default ManagePrivacySettings;
