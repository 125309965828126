import { ApolloProvider } from '@apollo/client';
import { OidcConfig } from '@hawaii-framework/oidc-implicit-core';
import { Loader, ThemeProvider, ThemeSwitcher, Themes } from '@vodafoneziggo/sandwich';
import GlobalThemeStyle from '@vodafoneziggo/sandwich/theme/global';
import { AuthProviderContainer } from 'components/Auth';
import Authentication from 'components/Authentication';
import { BreadcrumbsProvider } from 'components/Breadcrumbs';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';
import { IntlProvider } from 'components/Intl';
import TagPageVisitComponent from 'components/TagPageVisit';
import { UserProvider } from 'components/User';
import { getOidcConfig } from 'config/app.config';
import { OidcProvider } from 'containers/auth/oidcProvider';
import FeaturesContextProvider from 'containers/featuresContext';
import { client } from 'containers/graphql/graphql.context';
import { ProductSummariesProvider } from 'containers/products';
import { redirectToMakeAChoice } from 'containers/user';
import MaintenanceInProgress from 'pages/MaintenanceInProgress';
import ManagePrivacySettings, { SubscriptionAction } from 'pages/ManagePrivacySettings/ManagePrivacySettings';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { hot } from 'react-hot-loader';
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { initBundleAdviceTagging } from 'utils/bundle-advice';
import { setPageView } from 'utils/page-view';
import 'utils/polyfills.js';
import AppRoutes from './routes';
import GlobalAppStyle from './styles/app.styles';
import GridThemeProvider from './styles/utils/GridThemeProvider';

const AsyncSupport = React.lazy(() => import('./pages/Support' /* webpackChunkName: "Support" */));

initBundleAdviceTagging();

const App = () => {
  const [oidcConfig, setOidcConfig] = useState<OidcConfig>();

  useEffect(() => {
    setPageView();
    getOidcConfig().then(config => setOidcConfig(config));
  }, []);

  if (!oidcConfig) return null;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/*"
        element={
          <ThemeProvider theme={Themes.VODAFONE_WS10}>
            <GridThemeProvider>
              <IntlProvider>
                <GlobalThemeStyle />
                <GlobalAppStyle />
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <React.Suspense fallback={<Loader />}>
                    <ApolloProvider client={client}>
                      <TagPageVisitComponent />
                      <Routes>
                        <Route path="/index-werkzaamheden" element={<MaintenanceInProgress />} />

                        <Route
                          path="/support/*"
                          element={
                            <FeaturesContextProvider>
                              <AsyncSupport />
                            </FeaturesContextProvider>
                          }
                        />

                        <Route
                          path="/privacy/unsubscribe/"
                          element={
                            <ManagePrivacySettings
                              subscriptionAction={SubscriptionAction.UNSUBSCRIBE}
                              isUnauthenticated
                            />
                          }
                        />
                        <Route
                          path="/privacy/subscribe/"
                          element={
                            <ManagePrivacySettings
                              subscriptionAction={SubscriptionAction.SUBSCRIBE}
                              isUnauthenticated
                            />
                          }
                        />

                        <Route
                          path="*"
                          element={
                            <OidcProvider oidcConfig={oidcConfig}>
                              <AuthProviderContainer>
                                <FeaturesContextProvider>
                                  <UserProvider onUserHasMultipleBcsButNoneSelected={redirectToMakeAChoice}>
                                    <ProductSummariesProvider>
                                      <BreadcrumbsProvider>
                                        <Authentication.Wrapper>
                                          <AppRoutes />
                                        </Authentication.Wrapper>
                                      </BreadcrumbsProvider>
                                    </ProductSummariesProvider>
                                  </UserProvider>
                                </FeaturesContextProvider>
                              </AuthProviderContainer>
                            </OidcProvider>
                          }
                        />
                      </Routes>
                    </ApolloProvider>
                  </React.Suspense>
                </ErrorBoundary>
              </IntlProvider>
            </GridThemeProvider>
            {process.env.NODE_ENV === 'development' && <ThemeSwitcher />}
          </ThemeProvider>
        }
      />
    ),
    {
      basename: '/my',
    }
  );

  return <RouterProvider router={router} />;
};

export default hot(module)(App);
