import { Loader } from '@vodafoneziggo/sandwich';
import Oops from 'components/Oops';
import RedirectToDeepLink from 'components/RedirectToDeepLink';
import { Route404 } from 'components/Route404';
import ThemeContext, { Themes } from 'components/ThemeContext';
import {
  BUNDLE_ADVICE,
  DASHBOARD,
  INVOICES_MENU,
  PREPAID,
  PRODUCTS,
  PROFILE,
  SERVICE,
  USAGE,
} from 'config/permissions';
import InvoicesConversationContainer from 'containers/invoices-conversation.container';
import { Roles } from 'models';
import InvoicePaymentRedirect from 'pages/Invoices/InvoicePaymentRedirect';
import ManagePrivacySettings, { SubscriptionAction } from 'pages/ManagePrivacySettings/ManagePrivacySettings';
import OnlyLoanRedirection from 'pages/Products/DeviceAndSim/components/OnlyLoanRedirection/OnlyLoanRedirection';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Authentication from './components/Authentication';
import BaseLayout from './components/BaseLayout';
import ChooseBillingCustomer from './pages/ChooseBillingCustomer';

// Main chunks are created on highest level routes.
const AsyncDashboard = React.lazy(() => import('./pages/Dashboard' /* webpackChunkName: "Dashboard" */));
const AsyncProducts = React.lazy(() => import('./pages/Products' /* webpackChunkName: "Products" */));
const AsyncUsage = React.lazy(() => import('./pages/Usage' /* webpackChunkName: "Usage" */));
const AsyncProfile = React.lazy(() => import('./pages/Profile' /* webpackChunkName: "Profile1" */));
const AsyncService = React.lazy(() => import('./pages/Service' /* webpackChunkName: "Service" */));
const AsyncInvoices = React.lazy(() => import('./pages/Invoices' /* webpackChunkName: "Invoices" */));
const AsyncPrepaid = React.lazy(() => import('./pages/Prepaid' /* webpackChunkName: "Prepaid" */));
const AsyncRedTogether = React.lazy(() => import('./pages/RedTogether' /* webpackChunkName: "RedTogether" */));

const AsyncAddonList = !process.env.IS_PROD
  ? React.lazy(() => import('./pages/SuperAwesomeComponents/AddonList/AddonList' /* webpackChunkName: "AddonList" */))
  : React.lazy(() => import('./noop' /* webpackChunkName: "noop" */));

const AsyncNbaList = !process.env.IS_PROD
  ? React.lazy(() => import('./pages/SuperAwesomeComponents/NbaList/NbaList' /* webpackChunkName: "NbaList" */))
  : React.lazy(() => import('./noop' /* webpackChunkName: "noop" */));

const AsyncBundleAdvice = React.lazy(
  () => import('./pages/Products/Subscription/pages/BundleAdvice' /* webpackChunkName: "BundleAdvice" */)
);

const AppRoutes = () => {
  return (
    <ThemeContext.Provider value={Themes.RED}>
      <BaseLayout>
        <React.Suspense fallback={<Loader />}>
          <RedirectToDeepLink>
            <Routes>
              {/* This route is used to catch the vesta response. It makes sure we can break out of the iframe. */}
              <Route path="/rekeningen/betaling-redirect" element={<InvoicePaymentRedirect />} />
              <Route
                path="/"
                element={
                  <Authentication.RestrictedRoute permissions={DASHBOARD}>
                    <OnlyLoanRedirection>
                      <InvoicesConversationContainer.Provider>
                        <AsyncDashboard />
                      </InvoicesConversationContainer.Provider>
                    </OnlyLoanRedirection>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/privacy/user/unsubscribe/"
                element={<ManagePrivacySettings subscriptionAction={SubscriptionAction.UNSUBSCRIBE} />}
              />
              <Route
                path="/privacy/user/subscribe/"
                element={<ManagePrivacySettings subscriptionAction={SubscriptionAction.SUBSCRIBE} />}
              />
              <Route
                path="/maak-een-keuze"
                element={
                  <Authentication.RestrictedRoute>
                    <ChooseBillingCustomer />
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/rekeningen/*"
                element={
                  <Authentication.RestrictedRoute permissions={INVOICES_MENU}>
                    <InvoicesConversationContainer.Provider>
                      <AsyncInvoices />
                    </InvoicesConversationContainer.Provider>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/tegoed-en-verbruik/*"
                element={
                  <Authentication.RestrictedRoute permissions={USAGE}>
                    <OnlyLoanRedirection>
                      <AsyncUsage />
                    </OnlyLoanRedirection>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/producten/*"
                element={
                  <Authentication.RestrictedRoute permissions={PRODUCTS}>
                    <ThemeContext.Provider value={Themes.RED_DARK}>
                      <AsyncProducts />
                    </ThemeContext.Provider>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/red-together/*"
                element={
                  <Authentication.RestrictedRoute permissions={PRODUCTS}>
                    <ThemeContext.Provider value={Themes.RED}>
                      <AsyncRedTogether />
                    </ThemeContext.Provider>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/profiel/*"
                element={
                  <Authentication.RestrictedRoute permissions={PROFILE}>
                    <ThemeContext.Provider value={Themes.GRAY}>
                      <AsyncProfile />
                    </ThemeContext.Provider>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/service/*"
                element={
                  <Authentication.RestrictedRoute permissions={SERVICE}>
                    <AsyncService />
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/prepaid/*"
                element={
                  <Authentication.RestrictedRoute permissions={PREPAID}>
                    <ThemeContext.Provider value={Themes.RED}>
                      <AsyncPrepaid />
                    </ThemeContext.Provider>
                  </Authentication.RestrictedRoute>
                }
              />
              <Route
                path="/bundeladvies"
                element={
                  <Authentication.RestrictedRoute permissions={BUNDLE_ADVICE}>
                    <ThemeContext.Provider value={Themes.RED}>
                      <AsyncBundleAdvice />
                    </ThemeContext.Provider>
                  </Authentication.RestrictedRoute>
                }
              />

              {!process.env.IS_PROD && (
                <Route
                  path="/bundels"
                  element={
                    <Authentication.RestrictedRoute permissions={[[Roles.POSTPAID]]}>
                      <ThemeContext.Provider value={Themes.RED_DARK}>
                        <AsyncAddonList />
                      </ThemeContext.Provider>
                    </Authentication.RestrictedRoute>
                  }
                />
              )}

              {!process.env.IS_PROD && (
                <Route
                  path="/nba"
                  element={
                    <Authentication.RestrictedRoute>
                      <ThemeContext.Provider value={Themes.PURPLE}>
                        <AsyncNbaList />
                      </ThemeContext.Provider>
                    </Authentication.RestrictedRoute>
                  }
                />
              )}

              <Route path="/oeps" element={<Oops />} />

              <Route path="*" element={<Route404 />} />
            </Routes>
          </RedirectToDeepLink>
        </React.Suspense>
      </BaseLayout>
    </ThemeContext.Provider>
  );
};

export default AppRoutes;
